<template>
  <div class="SignInView">
    <div class="o-container-gray">
      <form novalidate class="md-layout" @submit.prevent="validateUser">
        <md-card>
          <md-progress-bar md-mode="indeterminate" v-if="isAuthLoading" />
          <md-card-header>
            <div class="md-title">{{ $t('signInView.title') }}</div>
          </md-card-header>

          <md-card-content>

            <md-field md-clearable :class="getValidationClass('email')">
              <label for="email">{{ $t('common.email') }}</label>
              <md-input v-model.trim="email"
                        type="email"
                        name="email"
                        id="email"
                        autocomplete="email" />
              <span v-if="!$v.email.required" class="md-error">{{ $t('common.auth.pleaseEnterEmail') }}</span>
              <span v-else-if="!$v.email.email" class="md-error">{{ $t('common.auth.incorrectEmail') }}</span>
            </md-field>

            <md-field :class="getValidationClass('password')">
              <label>{{ $t('signInView.password') }}</label>
              <md-input v-model="password"
                        type="password"
                        name="password"
                        id="password"
                        autocomplete="password" />
              <span class="md-error" v-if="!$v.password.required">{{ $t('common.auth.enterYourPassword') }}</span>
            </md-field>
            <div v-if="errors" v-html="errors" class="u-default-body u-red"></div>
          </md-card-content>

          <vue-recaptcha ref="invisibleRecaptcha"
                         @verify="onVerify"
                         @expired="onExpired"
                         size="invisible"
                         :sitekey="sitekey">
          </vue-recaptcha>

          <md-card-actions class="u-text-center">
            <md-button type="submit" class="md-raised md-primary auth-btn">{{ $t('common.auth.signIn') }}</md-button>
          </md-card-actions>

          <router-link class="o-default-body u-display-block element-spacing" :to="links.forgotPassword.page">{{ links.forgotPassword.text }}</router-link>

          <p class="o-default-body-small privacy-notice">
            {{ $t('signInView.personalDataPrivacy') }}
            {{ $t('signInView.personalDataPrivacyLabelBefore') }}
            <router-link :to="links.privacyPolicy.page">{{ links.privacyPolicy.text }}</router-link>
            {{ $t('signInView.personalDataPrivacyLabelAfter') }}
          </p>
        </md-card>

        <!-- <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar> -->
      </form>
    </div>

    <div class="o-container-gray">
      <md-button @click="Helpers.goToRoute('sign-up', $router)"
                 class="md-raised md-primary sign-up-btn">
        {{ $t('signInView.goToSignUp') }}
      </md-button>
    </div>

    <p class="o-default-body">{{ $t('common.error.furtherIssuesContact') }} {{ $t('contactEmailInreg') }}</p>

    <SecureLock />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  START_AUTH_LOADER,
  SIGNIN,
  UPDATE_USER_EMAIL,
  RESET_AUTH_ERROR
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import SecureLock from '@/components/util/SecureLock';

export default {
  name: 'SignInView',
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
    SecureLock
  },
  data() {
    return {
      email: '',
      password: '',
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS',
      links: {
        forgotPassword: {
          text: this.$t('signInView.forgotPassword'),
          page: '/forgot-password'
        },
        privacyPolicy: {
          text: this.$t('signInView.privacyPolicyLinkText'),
          page: '/privacy-policy'
        }
      }
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthLoading: state => state.auth.isAuthLoading
    })
  },
  mounted() {
    this.resetErrors();
  },
  methods: {
    signInUser(response) {
      this.$store.dispatch(START_AUTH_LOADER);

      this.$store
        .dispatch(SIGNIN, {
          email: this.email,
          password: this.password,
          captchaToken: response
        })
        .then(() => {
          this.$router.push({ name: 'home' })
          this.$store.dispatch(UPDATE_USER_EMAIL, this.email);
        })
    },
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.email = null
      this.password = null
      this.passwordConfirmation = null
    },
    validateUser() {
      this.$v.$touch()
      this.$refs.invisibleRecaptcha.execute();
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.$invalid) {
        this.signInUser(response)
      }
    },
    onExpired() {
      console.log('Expired')
    },
    resetErrors() {
      this.$store.dispatch(RESET_AUTH_ERROR);
    }
  },
};
</script>

<style lang="scss">
.SignInView {

  .md-button .md-button-content {
    font-size: 1.6rem;
    line-height: 1;
  }
}
</style>

<style lang="scss" scoped>
.SignInView {

  .md-card {
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem;
    width: 66rem;
  }

  .md-field:last-child {
    margin-bottom: 2.4rem;
  }

  .auth-btn {
    background-color: $palette-primary-color-four !important;
    height: 4rem;
    margin-bottom: .8rem;
    width: 100%;
  }

  .element-spacing {
    margin: 2.4rem;
    text-align: center;
  }

  .privacy-notice {
    margin: .8rem 0;
    padding-top: .8rem;
    line-height: 1.5;
  }

  .sign-up-btn {
    height: 4rem;
    padding: 0 .8rem;
  }
}
</style>
